
.TaskSelectComboBox--ComboBox{
    width: 300px;
}
/*IPHONE SE*/
@media screen and (max-width: 375px) {
    .TaskSelectComboBox--ComboBox{
        width: 250px;
    }
}
/*IPHONE XR*/
@media screen and (max-width: 414px) {
    .TaskSelectComboBox--ComboBox{
        width: 325px;
    }
}

/*IPHONE 12 Pro*/
@media screen and (max-width: 390px) {
    .TaskSelectComboBox--ComboBox{
        width: 298px;
    }
}
/*SURFACE DUO*/
@media screen and (max-width: 540px) {
}

/*GALAXY FOLD*/
@media screen and (max-width: 280px) {
    .TaskSelectComboBox--ComboBox{
        width: 200px;
    }
}
