.TrackedTaskForm{
    display: flex;
    flex-direction: column;
  }

  
  
  .TrackedTaskForm-StartEndDates{
    display: flex;
  }
  
  .TrackedTaskForm-ActiveTime{
    display: flex;
    flex-direction: column;
  }
  
  .TrackedTaskForm-Comments{
    display: flex;
    flex-direction: column;
  }
  
  .TrackedTaskForm-CommentsInput{
    height: 200px;
  }
  
  /*IPHONE SE*/
  @media screen and (max-width:375px) {
    .TrackedTaskForm-StartEndDates{
        display: inline-block;
      }
  }
  /*IPHONE XR*/
  @media screen and (max-width:414px) {
    .TrackedTaskForm-StartEndDates{
        display: inline-block;
      }  
  }
  /*SURFACE DUO*/
  @media screen and (max-width:540px) {
  
  }
  
  /*GALAXY FOLD*/
  @media screen and (max-width:280px) {
  
  }