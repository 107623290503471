.TrackedtaskItemView {
    width: 40%;
    
    display: flex;
    border-color: rgb(152, 157, 157);
    border-width: 0.5px;
    border-style: solid;
    padding: 2px;
    margin-top: 3px;
    border-radius: 5px;
    justify-content: space-between;
    align-items: center;
  }
  .TrackedtaskItemView-TaskName {
    font-weight: bold;
    color: rgb(113, 111, 107);
    margin-right: 20px;
    width: 40%;
    text-align: left;
    text-overflow: ellipsis;
    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
  }
  
  .TrackedtaskItemView-StartHour {
    color: green;
    width: 50px;
  }
  .TrackedtaskItemView-EndHour {
      width: 50px;
    color: red;
  }
  .TrackedTaskItemView-Total{
      display: flex;
      text-align: left;
      font-weight: bold;
      color: rgb(45, 45, 43);
      align-items: center;
      justify-content: space-between;
  }
  
  /*IPHONE SE*/
  @media screen and (max-width:375px) {
      .TrackedtaskItemView {
          display: inline-block;
          width: 95%;
          justify-content: left;
        }
        .TrackedtaskItemView-StartHour{
          text-align: left;
          width: 50px;
        }
        .TrackedtaskItemView-TaskName{
          width: 80%;
        }
        .TrackedtaskItemView-EndHour{
          text-align: right;
          width: 50px;
        }
  }
  /*IPHONE XR*/
  @media screen and (max-width:414px) {
    .TrackedtaskItemView {
      display: inline-block;
      width: 95%;
      justify-content: left;
    }
    .TrackedtaskItemView-StartHour{
      text-align: left;
      width: 50px;
    }
    .TrackedtaskItemView-TaskName{
      width: 80%;
    }
    .TrackedtaskItemView-EndHour{
      text-align: right;
      width: 50px;
    }  
  }
  /*SURFACE DUO*/
  @media screen and (max-width:540px) {
    .TrackedtaskItemView {
      display: inline-block;
      width: 95%;
      justify-content: left;
    }
    .TrackedtaskItemView-StartHour{
      text-align: left;
      width: 50px;
    }
    .TrackedtaskItemView-TaskName{
      width: 80%;
    }
    .TrackedtaskItemView-EndHour{
      text-align: right;
      width: 50px;
    }  
  }
  
  /*GALAXY FOLD*/
  @media screen and (max-width:280px) {
  
  }  