.track_task_controller{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
/*IPHONE SE*/
@media screen and (max-width:375px) {
    .track_task_controller{
        display: inline-block;
    }
}
/*IPHONE XR*/
@media screen and (max-width:414px) {
    .track_task_controller{
        display: inline-block;
    }
}
/*SURFACE DUO*/
@media screen and (max-width:540px) {
    .track_task_controller{
        display: inline-block;
    }
}

/*GALAXY FOLD*/
@media screen and (max-width:280px) {
    .track_task_controller{
        display: inline-block;
    }
}